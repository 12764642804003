import { dec, inc } from 'ramda'
import Swiper, { Autoplay, Pagination } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { windowSizes$ } from '@app-bootstrappers'
import { Breakpoint } from '@app-enums'
import { parseBase10 } from '@app-helpers'

export const phoneStatementSlider = () => {
    let isMobile: boolean | null = null

    const slideNumberElement = document.getElementById('phone-slide-number')!
    const slideNumberElementMobile = document.getElementById('phone-slide-number--mobile')!

    const nextButton = document.getElementById('nextSlide')!

    const phoneSwiperContainer = document.getElementById('phone-carousel')!
    const freeSwiperContainer = document.getElementById('phone-free-carousel')!

    const slidesLength = parseBase10(phoneSwiperContainer.dataset.length as string)

    const phoneSwiper = new Swiper(phoneSwiperContainer, {
        modules: [Autoplay],
        slidesPerView: 1,
        direction: 'vertical',
        loop: true,
        speed: 500,

        loopAdditionalSlides: 2,
        allowTouchMove: true,
        allowSlidePrev: true,
        allowSlideNext: false,
        resistanceRatio: 0.9,
        initialSlide: dec(slidesLength), // Last slide
        autoplay: {
            delay: 5000,
            reverseDirection: true,
            disableOnInteraction: false,
        },
    })

    const freeSwiper = new Swiper(freeSwiperContainer, {
        modules: [Pagination],
        loop: true,
        loopAdditionalSlides: 2,
        slidesPerView: 1,
        allowTouchMove: true,
        speed: 500,
        pagination: {
            el: '#phone-free-carousel .swiper-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet: (index: number, className: string) => `<span class="${className}"></span>`,
        },
        breakpoints: {
            [Breakpoint.LG]: {
                slidesPerView: 'auto',
                allowTouchMove: false,
            },
        },
    })

    windowSizes$.subscribe((size) => {
        if (size.width) {
            isMobile = size.width <= 576
        }
    })

    nextButton.addEventListener('click', () => phoneSwiper.slidePrev())

    nextButton.addEventListener('mouseenter', () => {
        freeSwiperContainer.classList.add('shifted')
        phoneSwiperContainer.classList.add('shifted')
    })

    nextButton.addEventListener('mouseleave', () => {
        freeSwiperContainer.classList.remove('shifted')
        phoneSwiperContainer.classList.remove('shifted')
    })

    const slideChange = (eventSwiper: Swiper, otherSwiper: Swiper, index: number) => {
        const { previousIndex, activeIndex } = eventSwiper

        Math.min(previousIndex, activeIndex)
        Math.max(previousIndex, activeIndex)

        if (activeIndex - previousIndex === 1) {
            otherSwiper.slidePrev(undefined, false)
        } else {
            otherSwiper.slideNext(undefined, false)
        }

        const value = index < 10
            ? `0${index.toString()}`
            : index.toString()

        slideNumberElement.innerText = value
        slideNumberElementMobile.innerText = value
    }

    phoneSwiper.on('slideChange', () => {
        if (! isMobile) {
            const index = inc(
                dec(slidesLength) - phoneSwiper.realIndex,
            )
            slideChange(phoneSwiper, freeSwiper, index)
        }
    })

    freeSwiper.on('slideChange', () => {
        if (isMobile) {
            const index = inc(
                freeSwiper.realIndex,
            )
            slideChange(freeSwiper, phoneSwiper, index)
        }
    })
}
